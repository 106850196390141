/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'NeutraTextTF-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('NeutraTextTF-Bold'), url('neutra-text-tf-bold-5871e0e1d072a.woff') format('woff');
    }
    

    @font-face {
    font-family: 'NeutraTextTF-Light';
    font-style: normal;
    font-weight: normal;
    src: local('NeutraTextTF-Light'), url('neutra-text-tf-light-5871e0fe13bff.woff') format('woff');
    }

    @font-face {font-family: "NeutraText-Book";
        src: url("../NeutraText-Book/@font-face/90820e3d7b32d7491b62bd1aae2aafbe.eot"); /* IE9*/
        src: url("../NeutraText-Book/@font-face/90820e3d7b32d7491b62bd1aae2aafbe.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url("../NeutraText-Book/@font-face/90820e3d7b32d7491b62bd1aae2aafbe.woff2") format("woff2"), /* chrome、firefox */
        url("../NeutraText-Book/@font-face/90820e3d7b32d7491b62bd1aae2aafbe.woff") format("woff"), /* chrome、firefox */
        url("../NeutraText-Book/@font-face/90820e3d7b32d7491b62bd1aae2aafbe.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("../NeutraText-Book/@font-face/90820e3d7b32d7491b62bd1aae2aafbe.svg#NeutraText-Book") format("svg"); /* iOS 4.1- */
      }