.login-container {
  position: relative;
  top: 40%;
  transform: perspective(1px) translateY(-50%);
  margin: 0 auto;
  width: calc(100% / 1.1);
  height: calc(100% / 2);
  max-width: 500px;
  background-color: $linearBackground;
  text-align: center;
  align-items: center;
}
.logo-container {
  // display: flex;
  width: 200px;
}
.login-form {
  margin-top: 50px;
  padding: 20px;
  margin-left: 25px;
  text-align: left;
}

.login-input {
  width: 250px;
  height: 40px;
}
.ant-form-item-explain-error {
  text-align: left;
}
