.info-container {
  position: relative;
  top: 30%;
  transform: perspective(1px) translateY(-50%);
  margin: 0 auto;
  width: calc(100% / 1.1);
  height: calc(100% / 2);
  max-width: 500px;
  background-color: $linearBackground;
  text-align: center;
  align-items: center;
}


.header-text {
  font-size: 16px;
  font-family: 'NeutraTextTF-Bold';
  color: $colorWhite;
}

.info-form {
  margin-top: 50px;
}
// .ant-form-item-explain-error {
//   text-align: center;
//   margin-left: -30px;
// }


// .info-inputt {
//   .ant-form-item-explain-error {
//     text-align: center;
//     margin-left: 85px;
//     @media (max-width: 400px) {
//       margin-left: 55px;
//     }
//   }

//   .ant-input-number-input-wrap {
//     margin-top: 3px;
//   }
// }

.info-inputt {
  .ant-form-item-explain-error {
    margin-left: 130px;
    @media (max-width: 400px) {
      margin-left: 135px;
    }
    @media (max-width: 500px) {
      margin-left: 55px;
    }
  }
}



.error-message {
  text-align: left;
  align-items: center;
  margin-left: 35px;
  font-size: 16px;
  font-family: 'NeutraText-Book';
  color: $colorRed;
}

.sucess-div {
  background-color: $colorWhite;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.title-text {
  // padding-top: 15%;
  font-size: 30px;
  font-family: 'NeutraTextTF-Bold';
  color: $colorBlue;
  margin-bottom: 20px;
}

.date-optional {
  text-align: center;
  margin-left: -200px;
  color: $colorWhite;
  font-size: 16px;
  font-family: 'NeutraText-Book';
  margin-bottom: 10px;
}
.modal-container {
  text-align: center;
}
.ant-modal-footer {
  display: none;
}
.ant-modal-content {
  border-radius: 20px;
}

.upload-profile .ant-upload-list {
  max-width: 250px !important;
  margin-left: 125px;
  @media (max-width: 600px) {
    margin-left: 55px;
  }
 }