// @import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');
@import '../../node_modules/antd/dist/antd.css';
// @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');
@import '../assets/neutra-text-tf-cufonfonts-webfont/style.css';


html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  // font-family: 'Raleway';
  color: #7a7a7a;
  font-size: 15px;
  overflow-x: hidden;
}

// body,
// div,
// h1,
// h2,
// h3,
// h4,
// h5,
// p,
// button {
//   font-family: 'Raleway' !important;
// }

.body {
  width: 100%;
  position: absolute;
  right: 0;
  background: transparent linear-gradient(180deg, #223369 0%, #243592 100%) 0% 0% no-repeat padding-box;
  overflow: hidden;
  z-index: 1;
  height: 100vh;
}
.main-bg {
  // background: #ebecef;
  height: 100vh;
  // display: flex;
}
.body-wrapper {
  display: flex;
}
.container {
  max-width: calc(100% / 1.01);
}
.main-wrapper {
  height: 100vh;
  width: 100%;
  // max-width: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  @media (min-width: 700px) {
    height: 60vh;
    // border-bottom-left-radius: 400px;
    // border-bottom-right-radius: 400px;
    // border-radius: 10px;
  }
  &.fullpage {
    height: 100vh !important;
    max-width: none;
    border-radius: 0;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@import './palette.scss', 'styles/main.scss', 'styles/auth.scss', 'styles/button.scss', 'styles/qrcode.scss', 'styles/info.scss'