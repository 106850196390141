.qrcode-container {
  position: relative;
  top: 40%;
  transform: perspective(1px) translateY(-50%);
  margin: 0 auto;
  width: calc(100% / 1.1);
  height: calc(100% / 2);
  max-width: 500px;
  background-color: $linearBackground;
  text-align: center;
  align-items: center;
}

.text {
  font-size: 16px;
  font-family: 'NeutraText-Book';
  color: $colorWhite;
  margin-top: 20px;
}

.qr-frame {
  width: 54%;
  height: 350px;
}
.logout-text {
  font-size: 14px;
  font-family: 'NeutraText-Book';
  margin-top: 3px;
}
.logout-style {
  margin: 20px;
  font-size: 14px;
  font-family: 'NeutraText-Book';
  color: $colorYellow;
  align-items: center;
  display: flex;
  column-gap: 5px;
}