.button-primary-container {
    margin-top: 40px;
  }
  .button-primary {
    background: #F2CD35;
    opacity: 1;
    border: none;
  }
  .button-make-money {
    width: 486px;
    height: 50px;
    background: #F2CD35;
    opacity: 1;
    border: none;
  }
  
  .button-makemoney-text {
    font-family: 'NeutraTextTF-Bold';
    text-align: center;
    letter-spacing: 3.2px;
    color: $colorBlue;
    font-size: 16px;
  }
  
  .button-text {
    text-align: center;
    letter-spacing: 3.2px;
    color: #243592;
    font-size: 16px;
    opacity: 1;
    font-family: 'NeutraTextTF-Bold';
  }
  
  .modal-button-make-money {
    width: 313px;
    height: 50px;
    background: $colorBlue;
    opacity: 1;
    border: none;
    margin-bottom: 20px;
  }
  
  .modal-button-makemoney-text {
    font-family: 'NeutraTextTF-Bold';
    text-align: center;
    letter-spacing: 3.2px;
    color: $colorWhite;
    font-size: 16px;
    height: 19px;
  }
  .button-subscribe {
    width: 486px;
    height: 50px;
    background: transparent;
    opacity: 1;
    border: 2px solid #F2CD35;
  }
  
  .button-subscribe-text {
    font-family: 'NeutraTextTF-Bold';
    text-align: center;
    letter-spacing: 3.2px;
    color: $colorWhite;
    font-size: 16px;
  }
  
  .ant-btn-default:hover, .ant-btn-default:focus {
    border-color: $colorYellow;
    background: $colorYellow;
    .button-subscribe-text {
      color: $colorBlue;
    }
    .ant-btn-loading-icon {
      position: absolute;
      left: 50px;
    }
  }
  
  .ant-btn-primary:hover, .ant-btn-primary:focus {
    border-color: $colorBlue;
    background: $colorBlue;
    .ant-btn-loading-icon {
      position: absolute;
      left: 50px;
    }
  }
  
  .ant-btn-loading-icon {
    position: absolute;
    left: 50px;
  }
  