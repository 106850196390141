$default-color: #7a7a7a;
$primary-color: #9c6ad5;
$purple: #913994;
$colorGreen: #53b848;
$colorBlue: #243592;
$colorLightRed: #fecdd4;
$colorRed: #d0404f;
$colorYellow: #F2CD35;
$colorLightYellow: #f5e4b6;
$colorGrey: #7D7D7D;
$colorMediumGrey: #b7b7b7;
$colorLightGrey: #f2f2f2;
$colorOrange: #ff9500;
$colorWhite: #ffffff;
$linearBackground: transparent linear-gradient(180deg, #223369 0%, #243592 100%) 0% 0% no-repeat padding-box;